export const Contact = (props) => {
 
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>G e t &nbsp; &nbsp;I n &nbsp; &nbsp;T o u c h</h2>
               
              </div>
              
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
          
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                 
                  <li>
                    <a href="https://github.com/labradorescence">
                      <i className='fa fa-github'></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/greemjellyfish">
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href="http://linkedin.com/in/greem-jellyfish-hai-pa-lee/">
                      <i className='fa fa-linkedin'></i>
                    </a>
                  </li>
                  <li>
                    <a href="http://greemjellyfish.medium.com/">
                      <i className='fa fa-medium'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p> 
          </p>
        </div>
      </div>
    </div>
  )
}
