export const Gallery = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>P O R T F O L I O</h2>
          <p>
          </p>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='https://alexandraleemcnutt.com/'
                    title='Infinite Vessels'
                    data-lightbox-gallery='gallery1'
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className='hover-text'>
                      <h4>Infinite Vessels</h4>
                    </div>
                    <img
                      src='img/portfolio/07-small.jpg'
                      className='img-responsive'
                      alt='Infinite Vessels'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='https://minhamagic.com/' 
                    title='Minha Magic'
                    data-lightbox-gallery='gallery1'
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className='hover-text'>
                      <h4>Minha Magic</h4>
                    </div>
                    <img
                      src='img/portfolio/08-small.jpg'
                      className='img-responsive'
                      alt='Minha Magic'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>

            

            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='https://handsomehand.com/'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className='hover-text'>
                      <h4>Handsome Hand</h4>
                    </div>
                    <img
                      src='img/portfolio/06-small.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='https://orangetimespace.com/' 
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className='hover-text'>
                      <h4>One World Acupuncture</h4>
                    </div>
                    <img
                      src='img/portfolio/01-small.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='https://haenyeospa.netlify.app/'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className='hover-text'>
                      <h4>Hae Nyeo Spa</h4>
                    </div>
                    <img
                      src='img/portfolio/02-small.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='https://orangetimespace.com/'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className='hover-text'>
                      <h4>Sensei Gallery</h4>
                    </div>
                    <img
                      src='img/portfolio/03-small.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='https://mwine.netlify.app/'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className='hover-text'>
                      <h4>Wine Tasting</h4>
                    </div>
                    <img
                      src='img/portfolio/04-small.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='https://labradorescence.github.io/growth-mindset/'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className='hover-text'>
                      <h4>Growth Mindset</h4>
                    </div>
                    <img
                      src='img/portfolio/05-small.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
